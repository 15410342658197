import React, { useEffect, useState } from "react"
import { Routes } from "react-router-dom"
import { useCookies, Cookies } from "react-cookie"

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom"
import { withStore, useStore } from "react-context-hook"
import "./App.css"

const loading = () => (
  <div className="main">
    <div className="main-container">
      <h5 style={{ fontSize: "16pt" }}>Loading...</h5>
    </div>
  </div>
)

const Login = React.lazy(() => import("./pages/Login"))
const Dashboard = React.lazy(() => import("./pages/Dashboard"))
const Editor = React.lazy(() => import("./pages/Editor"))
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"))

const authenticate = async (signal) => {
  return fetch(
    `${process.env.REACT_APP_ASSESSMENTS_API_HOST}/auth/authenticate`,
    {
      credentials: "include",
      signal,
    }
  )
    .then((r) => r.json())
    .then((res) => {
      if (res.status === 200) return res
      return false
    })
}

const PrivateRoute = ({ children }) => {
  const [authd, setAuthd] = useState(null)
  const [userAccount, setUserAccount] = useStore("userAccount", null)

  useEffect(() => {
    //const abortController = new AbortController();
    authenticate()
      .then((res) => {
        setAuthd(res)
        setUserAccount(res?.data)
      })
      .catch((err) => {
        console.log(err)
        setAuthd(false)
      })

    // return () => {
    //   abortController.abort()
    // }
  }, [])

  if (authd === null)
    // Stops us starting to load private page before we know auth status
    return loading()
  else return authd ? children : <Navigate to="/login" />
}

function App() {
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/editor/:template_id"
            element={
              <PrivateRoute>
                <Editor />
              </PrivateRoute>
            }
          />
          <Route path="/login" name="Login" element={<Login />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="*" name="PageNotFound" element={<PageNotFound />} />
        </Routes>
      </React.Suspense>
    </Router>
  )
}

export default withStore(App)
